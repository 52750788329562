import { SimpleDate, EqualityCheck } from '@idot-digital/calendar-api';
import { ServerHook } from './Components/Server/ServerContext';
import createHistory from './history';

import { AdditionalService, Category, Customer, Employee, missing, Service, TimeSlot } from './Types';
import { navigate } from './App';

class AppFunctions {
  // User selection and data
  protected categoryId: Category['id'] | missing = undefined;
  protected serviceId: Category['id'] | missing = undefined;
  protected additionalServiceIds: Category['id'][] = [];
  protected employeeId: Employee['id'] | missing = undefined;
  protected timeSlot: TimeSlot['startTime'] | missing = undefined;

  protected needToUpdateLanguage = false;
  protected phoneToUpdate: string | missing = undefined;
  protected updateEmailTo: string | missing = undefined;
  protected updateLanguageTo: string | missing = undefined;

  constructor() {
    this.listenHistory();
  }

  /* ---------- Helper Functions ---------- */

  /**
   * Update selection and user data while navigating through the app.
   */
  listenHistory = () => {
    createHistory.listen((location) => {
      navigate(location.location.pathname);
      // Clear complete previous data when navigating to a former page
      if (location.location.pathname === '/') {
        this.categoryId = undefined;
      } else if (location.location.pathname === '/services') {
        this.serviceId = undefined;
      } else if (location.location.pathname === '/additionalServices') {
        this.additionalServiceIds = [];
      } else if (location.location.pathname === '/appointments') {
        this.employeeId = undefined;
        this.timeSlot = undefined;
      } else if (location.location.pathname === '/book/register') {
        this.needToUpdateLanguage = false;
        this.phoneToUpdate = undefined;
        this.updateEmailTo = undefined;
      } else if (location.location.pathname === '/language') {
        this.updateLanguageTo = undefined;
      }
    });
  };

  /**
   * Push a new route to the history.
   * @param path The path to push.
   */
  pushHistory = (path: string) => {
    createHistory.push(path);
  };

  /**
   * Clear objects from undefined values.
   */
  clear = (obj: { [key: string | number]: unknown }) => {
    return Object.fromEntries(Object.entries(obj).filter((v) => v[0] !== undefined));
  };

  /**
   * Close app
   */
  closeApp = () => {
    window.location.reload();
    // TODO
    // close popup on site
  };

  /* ---------- Merger Functions ---------- */

  /**
   * Get all categories, services and additional services for the selection process.
   * @param useServer Complete data from server
   * @returns Currently selected category, service and additional services
   */
  getSelection = (
    useServer: ServerHook,
  ): {
    category: Category;
    service: Service;
    additionalServices: AdditionalService[];
    employee: Employee;
    timeSlot: TimeSlot;
  } => {
    const DEFAULT = {
      category: {
        id: -1,
        name: 'Kateogrie nicht gefunden',
        icon: '',
        hasDiscount: false,
        services: [],
        index: 0,
      },
      service: {
        id: -1,
        name: 'Service nicht gefunden',
        hasDiscount: false,
        price: 0,
        discountPrice: undefined,
        additionalServices: [],
      },
      additionalServices: [],
      employee: {
        id: -1,
        fullName: 'Mitarbeiter nicht gefunden',
        shortName: 'Mitarbeiter nicht gefunden',
        image: '',
        price: 0,
        discountPrice: undefined,
        timeSlots: [],
      },
      timeSlot: {
        startTime: SimpleDate.now(),
        price: 0,
        discountPrice: undefined,
      },
    };

    // Check for selection method
    const selection = this.serviceId
      ? this.getAdditionalServices(useServer.categories)
      : this.getCategory(useServer.categories);

    // Check for appointment selection
    const timeSlot = this.employeeId && this.timeSlot ? this.getTimeSlot(useServer.employees) : {};

    return {
      ...DEFAULT,
      ...this.clear(selection),
      ...this.clear(timeSlot),
    };
  };

  /* ---------- Selection Process ---------- */

  /**
   * Get currently selected category
   * @param categories All categories
   * @returns Currently selected category
   */
  protected getCategory = (categories: Category[] | missing) => {
    const category = categories?.find((category) => category.id === this.categoryId);

    if (!category) this.pushHistory('/');
    return { category };
  };
  /**
   * Get currently selected service
   * @param categories All categories
   * @returns Currently selected service and category
   */
  protected getService = (categories: Category[] | missing) => {
    const { category } = this.getCategory(categories);

    const service = category?.services.find((service) => service.id === this.serviceId);

    if (!service) this.pushHistory('/');
    return { category, service };
  };
  /**
   * Get currently selected additional services
   * @param categories All categories
   * @returns Currently selected additional services, service and category
   */
  protected getAdditionalServices = (categories: Category[] | missing) => {
    const { service, category } = this.getService(categories);

    const selectedAdditionalServices =
      service?.additionalServices.filter((additionalService) =>
        this.additionalServiceIds.includes(additionalService.id),
      ) || [];

    if (selectedAdditionalServices.length !== this.additionalServiceIds.length) this.pushHistory('/');
    return { additionalServices: selectedAdditionalServices, service, category };
  };

  /**
   * Select category to be used in the selection process
   * @param category The category to be selected
   */
  selectCategory = (category: Category) => {
    this.categoryId = category.id;

    // only one service available for category
    if (category.services.length === 1) {
      this.serviceId = category.services[0].id;

      // has additional services -> to additional
      if (category.services[0].additionalServices.length) this.pushHistory(`/additionalServices`);
      // has no additional services -> to appointments
      else this.pushHistory(`/appointments`);
    } else this.pushHistory(`/services`);
  };

  /**
   * Select service to be used in the selection process
   * @param service The service to be selected
   */
  selectService = (service: Service) => {
    this.serviceId = service.id;

    if (service.additionalServices.length) return this.pushHistory(`/additionalServices`);
    else this.pushHistory(`/appointments`);
  };

  /**
   * Select additional services to be used in the selection process
   * @param additionalService The additional service to be selected
   */
  selectAdditionalService = (additionalService?: AdditionalService) => {
    // Toggle additional service
    this.additionalServiceIds = additionalService ? [additionalService.id] : [];

    // Go to appointments
    this.applyAdditionalServices();
  };

  /**
   * Apply currently selected additional services and go to appointments selection
   */
  applyAdditionalServices = () => {
    this.pushHistory(`/appointments`);
  };

  /**
   * Get currently selected employee and time slot
   * @param employees All employees
   * @returns Currently selected employee and time slot
   */
  protected getTimeSlot = (employees: Employee[] | missing) => {
    const employee = employees?.find((employee) => employee.id === this.employeeId);

    const timeSlot = this.timeSlot
      ? employee?.timeSlots.find(
          (timeSlot) => timeSlot.startTime.isEqual(this.timeSlot as SimpleDate) === EqualityCheck.equal,
        )
      : undefined;

    if (!employee || !timeSlot) this.pushHistory('/');
    return { employee, timeSlot };
  };

  /**
   * Select time slot to be used in the booking process
   * @param employee The employee to be selected
   * @param timeSlot The time slot to be selected
   */
  selectTimeSlot = (employee: Employee, timeSlot: TimeSlot) => {
    this.employeeId = employee.id;
    this.timeSlot = timeSlot.startTime.copy(); // Clone start date

    this.pushHistory(`/summary`);
  };

  /* ---------- Summary ---------- */

  /**
   * Apply selection and continue to booking
   */
  applySelection = () => {
    this.pushHistory(`/book/register`);
  };

  /* ---------- Booking Process ---------- */

  /**
   * Store new email until booking is confirmed
   * @param request New email and callback functions
   */
  updateEmail = async (request: {
    email: string;
    useServer: ServerHook;
    setError: (error: string) => void;
    setLoading: (loading: boolean) => void;
  }) => {
    this.updateEmailTo = request.email;

    if (!this.needToUpdateLanguage) {
      await this.updateUserData(request);
      this.pushHistory(`/completed`);
    } else this.pushHistory(`/language`);
  };

  /**
   * Keep email as is and redirect to next page
   */
  doNotUpdateEmail = () => {
    if (this.needToUpdateLanguage) this.pushHistory(`/language`);
    else this.pushHistory(`/completed`);
  };

  /**
   * Store new language until booking is confirmed
   * @param request New language and callback functions
   */
  updateLanguage = async (request: {
    language: string;
    useServer: ServerHook;
    setError: (error: string) => void;
    setLoading: (loading: boolean) => void;
  }) => {
    this.updateLanguageTo = request.language;

    // Update user and redirect
    await this.updateUserData(request);
    this.pushHistory(`/completed`);
  };

  /**
   * Get data to be updated
   * @returns Stored email and language
   */
  getUpdateUserData = () => {
    return {
      phone: this.phoneToUpdate,

      email: this.updateEmailTo,
      language: this.updateLanguageTo,
    };
  };

  /**
   * Book appointment and redirect to next page
   * @param object Callback functions and data to be used in the booking process
   */
  bookAppointment = async ({
    setError,
    setLoading,
    canUpdateMail,
    useServer,
    customer,
  }: {
    setError: (error: string) => void;
    setLoading: (loading: boolean) => void;
    canUpdateMail: () => void;
    useServer: ServerHook;
    customer: Customer;
  }) => {
    setLoading(true);

    this.phoneToUpdate = customer.phone;
    if (!this.categoryId || !this.serviceId || !this.employeeId || !this.timeSlot) return;

    try {
      const response = await useServer.bookAppointment({
        ...customer,
        serviceId: this.serviceId,
        additionalServiceIds: this.additionalServiceIds,
        employeeId: this.employeeId,
        startTime: this.timeSlot,
      });

      setError('');
      setLoading(false);

      if (response.emailNotMatching) {
        canUpdateMail();
        this.pushHistory('/book/updateMail');
        this.needToUpdateLanguage = response.languageNotMatching;
      }
      // If email does not need to be updated redirect to new location
      else {
        if (response.languageNotMatching) this.pushHistory(`/language`);
        else this.pushHistory(`/completed`);
      }
    } catch (e) {
      setError((e as Error).message);
      setLoading(false);
    }
  };

  /**
   * Update language and email of user
   * @param object Callback functions and server hook to update user data
   */
  updateUserData = async ({
    useServer,
    setError,
    setLoading,
  }: {
    useServer: ServerHook;
    setError: (error: string) => void;
    setLoading: (loading: boolean) => void;
  }) => {
    setLoading(true);
    setError('');

    if (!this.phoneToUpdate) return;

    try {
      await useServer.updateLanguageAndEmail({
        phone: this.phoneToUpdate,
        email: this.updateEmailTo,
        language: this.updateLanguageTo,
      });
    } catch (e) {
      setError((e as Error).message);
    }

    setLoading(false);
  };

  /* ---------- Complete ---------- */

  /**
   * Redirect to summary after successful booking
   */
  openFinalSummary = () => {
    this.pushHistory(`/summary/true`);
  };

  /**
   * Exit summary overview then completed booking
   */
  exitSummary = () => {
    this.pushHistory(`/completed`);
  };
}

const appFunctions = new AppFunctions();
export default appFunctions;

if (import.meta.env.DEV) {
  // @ts-ignore
  window.appFunctions = appFunctions;
}
