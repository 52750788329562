const FLAG_BASE_URL = import.meta.env.DEV ? 'http://localhost:3000/flags' : 'https://app.emmasy.com/flags';

const config = {
  languages: [
    { name: 'deutsch', icon: `${FLAG_BASE_URL}/w40/de.png` },
    { name: 'englisch', icon: `${FLAG_BASE_URL}/w40/us.png` },
    { name: 'französisch', icon: `${FLAG_BASE_URL}/w40/fr.png` },
    { name: 'russisch', icon: `${FLAG_BASE_URL}/w40/ru.png` },
    {
      name: 'andere',
      icon: `${FLAG_BASE_URL}/andere_flagge.jpg`,
    },
  ],
  serverBaseLink: import.meta.env.DEV ? window.location.origin + '/api/' : 'https://app.emmasy.com/api/',
  apiPath: 'main/',
  filePath: 'upload/uploads/',
  flagsBaseURL: FLAG_BASE_URL + '/',
  devNamespace: 1,
  devAutofillUser: {
    name: 'Testuser',
    phone: '+4915128202299',
    email: 'user1@random-mail.de',
    acceptAGB: true,
  },
};

export default config;
