import React from 'react';
import { MemoryRouter } from 'react-router';
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SnackbarProvider } from 'notistack';
import { deDE } from '@mui/x-date-pickers/locales';
import App from './App';
import RootPopup from './Components/RootPopup/RootPopup';
import { ServerProvider } from './Components/Server/ServerContext';
import config from './config';
import { createRoot } from 'react-dom/client';
import 'dayjs/locale/de';

if (!window.emmasy_namespace) {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('namespace') || searchParams.has('ns')) {
    const namespace = searchParams.get('namespace') || searchParams.get('ns');
    const parsedNamespace = parseInt(namespace ?? '');
    if (!isNaN(parsedNamespace)) {
      window.emmasy_namespace = parsedNamespace;
    }
  }
}
if (import.meta.env.DEV) window.emmasy_namespace = config.devNamespace;

const globalTheme = createTheme(
  {
    palette: {
      primary: {
        main: '#ED8443',
        contrastText: '#fff',
        light: '#f2a576',
      },
      secondary: {
        main: '#FFEFCA',
        dark: '#dfe0df9e',
        light: '#FFF8EA9e',
      },
      text: {
        primary: '#201719',
        disabled: '#DFE0DF',
        secondary: '#5f5f5f',
      },
      background: {
        default: '#fff',
      },
      error: {
        main: '#A70202',
      },
    },
  },
  deDE,
);

const theme = createTheme(
  {
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&:focus': {
              backgroundColor: `${globalTheme.palette.primary.main} !important`,
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              background: globalTheme.palette.primary.main,
              color: globalTheme.palette.primary.contrastText,
              ':hover': {
                background: globalTheme.palette.primary.main,
                color: globalTheme.palette.primary.contrastText,
              },
            },
            ':hover': {
              background: globalTheme.palette.primary.light,
            },
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
    },
  },
  globalTheme,
);

const root = createRoot(document.getElementById('emmasy-dialog-wrapper')!);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <ServerProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <MemoryRouter>
              <RootPopup>
                <App />
              </RootPopup>
            </MemoryRouter>
          </LocalizationProvider>
        </ServerProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
