import React from 'react';

import { StaticDatePicker } from '@mui/x-date-pickers';
import { Box, SxProps, Theme, useTheme } from '@mui/system';

import { SimpleDate } from '@idot-digital/calendar-api';

import DiscountMarqee from '../Discounts/DiscountMarqee';
import { useServer } from '../Server/ServerContext';
import AppointmentList from './AppointmentList';
import EmployeePicker from './EmployeePicker';
import Subtitle from '../AppHeadBar/Subtitle';
import appFunctions from '../../AppFunctions';

import { Employee } from '../../Types';
import dayjs from 'dayjs';
import { getAvailableDays } from '../Server/ServerContext.functions';

const styles: { [key: string]: SxProps<Theme> } = {
  topWrapper: {
    display: 'flex',
    flexWrap: {
      sm: 'wrap',
      md: 'nowrap',
    },
    width: '100%',
    justifyContent: 'center',
    minWidth: {
      sm: 'min(42rem, 80vw)',
    },
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    paddingTop: (theme) => theme.spacing(6),
  },
  datepicker: {
    flex: {
      xs: '1 1 100%',
      sm: '0 0 50%',
    },
    padding: (theme) => ({
      sm: theme.spacing(1),
      md: `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(1)}`,
    }),
    boxSizing: 'border-box',
  },
  employeePicker: {
    minWidth: {
      sm: 'min(20rem, 100%)',
    },
    flex: {
      xs: '1 1 100%',
      sm: '1 1 50%',
    },
  },
};

export default function AppointmentPickerWrapper() {
  const { employees, requestTimeSlots, settings } = useServer();
  const { service, category, additionalServices } = appFunctions.getSelection(useServer());
  const theme = useTheme();

  const [selectedDate, setSelectedDate] = React.useState(dayjs());
  const [selectedEmployees, setSelectedEmployees] = React.useState<Employee['id'][]>([]);

  const mounted = React.useRef(true);
  React.useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  const [loading, setLoading] = React.useState(false);

  const filteredEmployees = React.useMemo(() => {
    return selectedEmployees
      .map((id) => employees?.find((employee) => employee.id === id))
      .filter(Boolean) as Employee[];
  }, [selectedEmployees, employees]);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await requestTimeSlots({
          additionalServiceIds: additionalServices.map((service) => service.id),
          categoryId: category.id,
          serviceId: service.id,
          date: SimpleDate.fromDate(selectedDate.toDate()),
        });
      } catch (e) {
        console.error('Error loading timeslots', e);
      } finally {
        if (mounted.current) setLoading(false);
      }
    })();
    //eslint-disable-next-line
  }, [selectedDate]);

  const [currentMonth, setCurrentMonth] = React.useState(new Date());
  const [availableDays, setAvailableDays] = React.useState<SimpleDate[] | null>(null);

  React.useEffect(() => {
    (async () => {
      try {
        setAvailableDays(null);
        let monthStart = SimpleDate.fromDate(currentMonth);
        monthStart.setDay(1);
        if (monthStart.exportInt() < SimpleDate.now().exportInt()) monthStart = SimpleDate.now();
        const monthEnd = monthStart.copy();
        monthEnd.setMonth(monthEnd.getMonth() + 1, 1);
        monthEnd.add(0, 0, -1);
        console.log({
          monthStart: monthStart.getDateString(),
          monthEnd: monthEnd.getDateString(),
        });
        const days = await getAvailableDays({
          additionalServiceIds: additionalServices.map((service) => service.id),
          serviceId: service.id,
          to: monthEnd,
          from: monthStart,
        });
        if (mounted.current) setAvailableDays(days);
      } catch (e) {
        console.error('Error loading available days', e);
      }
    })();
    //eslint-disable-next-line
  }, [currentMonth]);

  return (
    <Box>
      {service?.discountTexts && <DiscountMarqee discountTexts={service.discountTexts} />}
      <Subtitle>{service.name}</Subtitle>

      <Box sx={styles.topWrapper}>
        <Box sx={styles.datepicker}>
          <StaticDatePicker
            value={selectedDate}
            onChange={(date) => setSelectedDate(date || dayjs())}
            shouldDisableDate={(
              date, // disable all days before today
            ) =>
              date.toDate().setHours(0, 0) < new Date().setHours(0, 0, 0, 0) ||
              // disable all days before the booking period starts
              date.toDate().setHours(23, 59) <= settings.bookingPeriod.start.toDate().getTime() ||
              // disable all days after the booking period ends
              date.toDate().setHours(0, 0) >= settings.bookingPeriod.end.toDate().getTime()
            }
            openTo="day"
            disablePast
            slotProps={{
              toolbar: {
                hidden: true,
              },
              actionBar: {
                hidden: true,
                actions: [],
              },
              day(ownerState) {
                if (availableDays === null) return {};
                if (
                  availableDays.some((day) => {
                    const day1 = day.toDate();
                    const day2 = ownerState.day.toDate();
                    return day1.setHours(0, 0) === day2.setHours(0, 0);
                  })
                ) {
                  return {};
                }
                return {
                  sx: {
                    color: theme.palette.text.disabled,
                    transition: (theme.transitions as any).create(['color']),
                  },
                };
              },
            }}
            views={['day']}
            minDate={dayjs()}
            onMonthChange={(date) => setCurrentMonth(date.toDate())}
          />
        </Box>
        <Box sx={styles.employeePicker}>
          <EmployeePicker selected={selectedEmployees} setSelected={setSelectedEmployees} />
        </Box>
      </Box>
      <AppointmentList
        filteredEmployees={filteredEmployees.length ? filteredEmployees : employees || []}
        loading={loading}
      />
    </Box>
  );
}
