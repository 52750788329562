import { Button, Typography } from '@mui/material';

import appFunctions from '../../AppFunctions';

import { Styles } from '../../Types';

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(3),
    margin: (theme) => theme.spacing(1),
    flexDirection: 'column',
  },
};

export default function NoAdditionalServiceTile(props: { text: string }) {
  return (
    <Button
      onClick={() => appFunctions.selectAdditionalService()}
      sx={styles.wrapper}
      color="primary"
      variant="contained"
    >
      <Typography variant="h6" textAlign="center">
        {props.text}
      </Typography>
    </Button>
  );
}
