import { Groups } from '@mui/icons-material';
import { SxProps, Theme, useTheme } from '@mui/system';
import { List, ListItemButton, ListItemText, ListItemAvatar } from '@mui/material';

import PriceVisualizer from '../Discounts/PriceVisualizer';
import { useServer } from '../Server/ServerContext';
import Loading from '../Loading/Loading';

import { Employee, Styles } from '../../Types';
import EmployeeAvatar from './EmployeeAvatar';

export interface EmployeePickerProps {
  selected: Employee['id'][];
  setSelected: (selected: Employee['id'][]) => void;
}

const styles = {
  wrapper: {
    padding: (theme) => ({
      xs: theme.spacing(1),
      md: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} 0`,
    }),
  },
  employee: {
    display: 'flex',
    alignItems: 'center',
    padding: (theme) => theme.spacing(2),
    margin: (theme) => theme.spacing(1),
    width: (theme) => `calc(100% - ${theme.spacing(2)})`,
    borderRadius: 1,
    color: (theme) => theme.palette.text.default,
    '&:hover': {
      background: (theme) => theme.palette.text.disabled,
    },
  },
  icon: {
    height: '3rem',
    width: '3rem',
  },
  iconSelected: {
    filter: 'grayscale(1)',
  },
} as Styles;

export default function EmployeePicker(props: EmployeePickerProps) {
  const theme = useTheme();
  const { employees } = useServer();

  const toggle = (employee: Employee) => {
    if (props.selected.includes(employee.id)) props.setSelected(props.selected.filter((id) => id !== employee.id));
    else props.setSelected([...props.selected, employee.id]);
  };

  return employees ? (
    <List sx={styles.wrapper}>
      <ListItemButton onClick={() => props.setSelected([])} sx={styles.employee} selected={props.selected.length === 0}>
        <ListItemAvatar>
          <Groups sx={styles.icon} />
        </ListItemAvatar>
        <ListItemText primary="Beliebig" primaryTypographyProps={{ variant: 'body1' }} />
      </ListItemButton>

      {employees.map((employee) => (
        <ListItemButton
          key={employee.id}
          onClick={() => {
            toggle(employee);
            setTimeout(() => {
              // blur selected item to prevent background from being colored and making it seem like it's still selected
              if (
                document.activeElement &&
                'blur' in document.activeElement &&
                typeof document.activeElement.blur === 'function'
              )
                document.activeElement.blur();
            });
          }}
          sx={styles.employee}
          color="primary"
          selected={props.selected.includes(employee.id)}
        >
          <ListItemAvatar>
            <EmployeeAvatar
              employee={employee}
              sx={
                {
                  ...styles.icon,
                  ...(props.selected.includes(employee.id) && styles.iconSelected),
                } as SxProps<Theme>
              }
            />
          </ListItemAvatar>
          <ListItemText
            primary={employee.fullName}
            secondary={
              <PriceVisualizer
                price={employee}
                color={props.selected.includes(employee.id) ? theme.palette.text.disabled : theme.palette.text.primary}
                context="employee_picker"
              />
            }
          />
        </ListItemButton>
      ))}
    </List>
  ) : (
    <Loading />
  );
}
