import { ProviderContext } from 'notistack';
import { SimpleDate } from '@idot-digital/calendar-api';
import mobile from 'is-mobile';

/**
 * Tries to share appointment information appropriately, otherwise copies data to clipboard
 * @param serviceName name of appointment service
 * @param employeeName name of employee
 * @param duration duration of appointment
 */
export function share(serviceName: string, employeeName: string, duration: SimpleDate, snackbar: ProviderContext) {
  const appointmentString = getAppointmentString(serviceName, employeeName, duration);

  // Try sharing on mobile - fallback to copying to clipboard
  // otherwise copy to clipboard just as pc does

  // @ts-ignore
  if (mobile() && navigator.canShare && navigator.canShare({ text: appointmentString }))
    navigator
      .share({
        title: 'Friseurtermin teilen',
        text: appointmentString,
      })
      .catch(() => copyAppointmentStringToClipboard(appointmentString, snackbar));
  else if (navigator.clipboard && window.isSecureContext) copyAppointmentStringToClipboard(appointmentString, snackbar);
  // Fallback if window is not in secure context
  else snackbar.enqueueSnackbar('Termin konnte nicht geteilt werden', { variant: 'error' });
}

/**
 * Copies appointment information to clipboard
 * @param appointmentString information to share
 */
export function copyAppointmentStringToClipboard(appointmentString: string, snackbar?: ProviderContext) {
  navigator.clipboard.writeText(appointmentString);
  snackbar?.enqueueSnackbar('Termin wurde in die Zwischenablage kopiert', { variant: 'info' });
}

/**
 * Returns appointment information
 * @param serviceName name of appointment service
 * @param employeeName name of employee
 * @param duration duration of appointment
 * @returns string containing appointment information
 */
export function getAppointmentString(serviceName: string, employeeName: string, duration: SimpleDate): string {
  return `Sie haben am ${duration.getDateTimeString()} einen Termin bei ${employeeName} für ${serviceName}`;
}
