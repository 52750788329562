import { CalendarToday, Check, Details, Share } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useServer } from '../Server/ServerContext';
import appFunctions from '../../AppFunctions';

import { Styles } from '../../Types';
import { share } from './CompletedWrapperFunctions';
import { useSnackbar } from 'notistack';

const styles: Styles = {
  wrapper: {
    padding: (theme) => theme.spacing(2),
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  successText: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    alignItems: 'center',
    justifyContent: 'center',
    margin: (theme) => '0 ' + theme.spacing(5) + ' ' + theme.spacing(5),
  },
  checkmark: {
    height: '5rem',
    width: '5rem',
    boxSizing: 'border-box',
    paddingRight: (theme) => theme.spacing(1),
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    width: '100%',
  },
  button: {
    flex: '1 1 0',
    '&:not(:last-child)': {
      marginRight: (theme) => ({
        xs: 0,
        sm: theme.spacing(1),
      }),
      marginBottom: (theme) => ({
        xs: theme.spacing(1),
        sm: 0,
      }),
    },
    '.MuiButton-startIcon': {
      xs: {
        position: 'absolute',
        left: 8,
      },
      sm: {
        position: 'relative',
        left: 0,
      },
    },
  },
  buttonIcon: {
    height: '2rem',
    width: '2rem',
  },
};

export default function CompletedWrapper() {
  const { booking } = useServer();
  const snackbar = useSnackbar();

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.successText}>
          <Check color="primary" sx={styles.checkmark} />
          <Typography variant="h4" color="primary" textAlign="center">
            Termin erfolgreich gebucht
          </Typography>
        </Box>
        <Box sx={styles.buttonWrapper}>
          <Button
            startIcon={<Share sx={styles.buttonIcon} />}
            variant="contained"
            sx={styles.button}
            size="large"
            onClick={() => booking && share(booking.serviceName, booking.employeeName, booking.startDate, snackbar)}
          >
            Teilen
          </Button>
          <Button
            startIcon={<Details sx={styles.buttonIcon} />}
            variant="contained"
            size="large"
            sx={styles.button}
            onClick={() => appFunctions.openFinalSummary()}
          >
            Übersicht anzeigen
          </Button>
          {(!booking || booking.icsLink) && (
            <Button
              startIcon={<CalendarToday sx={styles.buttonIcon} />}
              size="large"
              variant="contained"
              sx={styles.button}
              href={booking?.icsLink || ''}
              rel="noopener noreferrer"
              target="_blank"
            >
              Zum Kalendar hinzufügen
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}
